
import {
  defineComponent,
  onMounted,
  ref,
  toRefs,
  watch,
  nextTick
} from 'vue';
import { ElMessage, ElTable } from 'element-plus';

import {
  ResponseError,
  getBlockModels,
  MAX_PER_PAGE
} from '@/services/api';
import {
  useSaveBlockModels
} from '@/composables/api';
import { isNumber } from '@/utils';
import { BlockModel } from '@/interfaces/Block';
import { DEFAULT_SORT } from '../constants';
import { useI18n } from 'vue-i18n';


export default defineComponent({
  props: {
    blockId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const { blockId } = toRefs(props);
    const models = ref([]);
    const sortOrder = ref();
    const { t } = useI18n();


    const {
      isLoading,
      mutate
    } = useSaveBlockModels();

    const tableRef = ref<InstanceType<typeof ElTable>>(null);

    const multipleSelections = ref<BlockModel[]>([]);
    watch(multipleSelections, (selections) => {
      models.value.forEach(video => {
        const target = selections.find(({ id }) => id === video.id);
        if (target && !isNumber(target.sort)) target.sort = DEFAULT_SORT;
      });
    });

    const handleSelectionChange = (selections: BlockModel[]) => {
      multipleSelections.value = selections;
    };

    const toggleSelections = async() => {
      await nextTick();
      models.value.forEach((video) => {
        if (isNumber(video.sort)) {
          tableRef.value.toggleRowSelection(video, true);
        }
      });
    };

    watch(models, async() => {
      toggleSelections();
    }, { deep: true });

    const fetchBlockModels = async() => {
      const { data } = await getBlockModels({
        blockId: blockId.value,
        query: {
          sort: sortOrder.value,
          perPage: MAX_PER_PAGE
        }
      });
      models.value = data.map(({ sort, ...resVideo }) => ({
        ...resVideo,
        ...(isNumber(sort) && { sort })
      }));

      toggleSelections();
    };

    onMounted(() => {
      fetchBlockModels();
    });

    const handleSortChange = ({ order }) => {
      sortOrder.value = order === 'ascending' ? 'asc' : 'desc';

      fetchBlockModels();
    };

    const saveModels = () => {
      mutate(
        {
          blockId: blockId.value,
          data: {
            modelIds: multipleSelections.value.map(({ id, sort }) => ({ id, sort }))
          }
        },
        {
          onSuccess() {
            ElMessage.success('success!');

            fetchBlockModels();
          },
          onError(error: ResponseError) {
            ElMessage.error(error.response?.data.message);
          }
        }
      );
    };

    return {
      models,
      tableRef,
      isLoading,
      handleSelectionChange,
      handleSortChange,
      t,
      saveModels
    };
  }
});
