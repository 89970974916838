
import {
  defineComponent,
  onMounted,
  PropType,
  ref,
  toRefs,
  watch
} from 'vue';
import { ElMessage } from 'element-plus';

import {
  ResponseError,
  updateBlockPhotos,
  getPhotos,
  MAX_PER_PAGE
} from '@/services/api';
import { Block } from '@/interfaces/Block';

export default defineComponent({
  props: {
    block: {
      type: Object as PropType<Block>,
      required: true
    }
  },
  setup(props) {
    const { block } = toRefs(props);

    const photoIds = ref([...(block.value as Block).photoIds]);
    watch(block, (block: Block) => {
      photoIds.value = block.photoIds;
    });

    const photoOptions = ref([]);
    onMounted(async() => {
      const { data } = await getPhotos({
        query: {
          perPage: MAX_PER_PAGE
        }
      });

      photoOptions.value = data;
    });

    const isLoading = ref(false);
    const updatePhotos = async() => {
      isLoading.value = true;

      try {
        await updateBlockPhotos({
          blockId: `${block.value.id}`,
          data: {
            photoIds: photoIds.value
          }
        });
      } catch (error) {
        ElMessage.error((error as ResponseError).response?.data.message);
        isLoading.value = false;
        return;
      }

      ElMessage.success('success!');
      isLoading.value = false;
    };

    return {
      photoIds,
      photoOptions,
      isLoading,
      updatePhotos
    };
  }
});
